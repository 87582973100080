<template lang="pug">
v-container(fill-height,fluid,grid-list-xl)
  v-flex(md12)
    v-layout(row, justify-center)
      h3 QRCode de autenticação do usuário CONTROL para o período do {{ trimestre }}

    v-layout(row, justify-center)

        h3(@click="copyKey" style="cursor: pointer;") {{ key }}
        v-slide-x-transition
          v-icon( v-if="showIcon" class="mx-2" color="success") fa-check

    v-layout(row, justify-center, wrap)
      v-flex(md4)
        v-img( :src="url", alt="QR Code" )

</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data: () => ({
    hashBase: 'NNNF2TZPJFEVKZDHIVSEIURJJNUDANCH',
    trimestre: '',
    ano: '',
    key: null,
    url: null,
    showIcon: false,
  }),
  mounted() {
    this.isControl(true);
    this.setTitle('Autenticação');

    let Ano = '';
    let Mes = 0;
    let Trimestre = 0;
    let I = 0;
    let Result = this.hashBase;

    Ano = new Date().getFullYear().toString();
    Mes = new Date().getMonth() + 1;

    Trimestre = Math.trunc((Mes + 2) / 3);
    Ano = (parseInt(Ano) * Trimestre).toString();

    for (I = 0; I < Ano.length; I++) {
      Result += String.fromCharCode(parseInt(Ano[I]) + 65);
    }

    Result += String.fromCharCode(Trimestre + 65);

    this.ano = new Date().getFullYear().toString();
    this.trimestre = `${Trimestre}º trimestre de ${this.ano}`;
    this.key = Result;
    this.url = `https://quickchart.io/chart?cht=qr&chs=500x500&chl=otpauth://totp/Senha%20ControlSoft%20-%20${this.ano}/${Trimestre}?secret=${Result}`;
  },
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    copyKey() {
      navigator.clipboard.writeText(this.key)
        .then(() => {
          this.showIcon = true;
          setTimeout(() => {
            this.showIcon = false;
          }, 3000);
        });
    },
  },
};
</script>
